import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
import Button from "../../components/app2/Button";
import BackupModal from "../../components/MainMenuComponents/BackupModal";
import DataSelectionModel from "../../components/MainMenuComponents/DataSelectionModel";
import LoginModal from "../../components/MainMenuComponents/LoginModal";
import { saveDefaultSettings, savePrivilege } from "../../components/app2/globalfunctions";
import { useLanguage } from "../../components/app2/LanguageContext";
import { useAlert } from "../../providers/AlertProvider";
import logo from "../../media/PYXLOGOTRANSPERENT.png";
import backup from "../../media/backup.png";
import database from "../../media/database.png";
import license from "../../media/license.png";
import { useSystemFunctions } from "../../providers/SystemFunctionsProvider";
import { TUser } from "../../providers/UserFunctionsProvider";

export default function HomeScreen(props: THomeScreenProps) {
    const { lang, langHandler } = useLanguage();

    const [showLicenseModal, setshowLicenseModal] = useState(false);
    const [showBackupModal, setshowBackupModal] = useState(false);
    const [showDataSelectionModel, setshowDataSelectionModel] = useState(false);
    const [showLoginModel, setshowLoginModel] = useState(false);

    const [isError, setIsError] = useState(false);
    const alert = useAlert();
    const sysFunc = useSystemFunctions();

    return (
        <>
            <div className="flex h-[100%] w-[100%] flex-col items-center justify-start bg-zinc-800 p-2  ">
                <div className="cBorder my-auto flex  h-[85%] w-[min(90%,150rem)]  flex-row items-center justify-between rounded-2xl p-1 text-lg font-semibold italic text-blue-600 shadow-lg">
                    <div className="m-auto flex h-[100%] w-[100%] flex-row items-center justify-between gap-3 overflow-y-auto rounded-xl bg-zinc-800 p-2 text-lg font-semibold italic text-blue-600 shadow-lg max-lg:flex-col">
                        <div className="flex h-full flex-col justify-between  ">
                            <Button
                                variant="text"
                                onClick={() => {
                                    setshowDataSelectionModel(true);
                                }}
                            >
                                <div className="flex h-[150px] w-[300px] flex-col items-center justify-center rounded-md bg-zinc-300  p-1 text-xl font-semibold text-zinc-800">
                                    <img
                                        draggable={false}
                                        src={database}
                                        alt="database"
                                        className="h-10 w-10 rounded"
                                    />
                                    <div>data</div>
                                    <div>[{localStorage.getItem("dbname")}]</div>
                                </div>
                            </Button>
                            <Button variant="text">
                                <div className="flex h-[150px] w-[300px] flex-col justify-center rounded-md bg-zinc-300  p-1 font-semibold text-zinc-800">
                                    <div className="flex flex-col justify-center  ">
                                        <div>☎{lang["TEL"]} </div>
                                        <div className="flex flex-row flex-nowrap justify-between pl-1">
                                            <div className={"mx-2 whitespace-nowrap"}>
                                                (+961)81 601 959
                                            </div>
                                            <div>/</div>
                                            <div className={"mx-2 whitespace-nowrap"}>
                                                (+961)71 562 473
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Button>
                        </div>
                        <div className="flex h-full flex-col items-center justify-around gap-2">
                            <div className="  cBorder2 h-[10rem] w-fit rounded-2xl p-1 ">
                                <img
                                    draggable={false}
                                    src={logo}
                                    alt="Logo"
                                    className="h-full rounded-xl bg-neutral-800 "
                                />
                            </div>
                            <div>
                                <Button
                                    style={{ boxShadow: "10px 10px 20px 0px black" }}
                                    variant="contained"
                                    onClick={() => {
                                        setshowLoginModel(true);
                                        setIsError(false);
                                    }}
                                    color="blue"
                                >
                                    <div className=" px-20 py-11 text-2xl font-semibold">Login</div>
                                </Button>
                            </div>
                        </div>
                        <div className="flex h-full flex-col justify-between">
                            <Button variant="text" onClick={() => setshowBackupModal(true)}>
                                <div className="flex h-[150px] w-[300px] flex-col items-center justify-center rounded-md bg-zinc-300 p-1 align-middle font-semibold text-zinc-800">
                                    <img
                                        draggable={false}
                                        src={backup}
                                        alt=""
                                        className="h-10 w-10 rounded"
                                    />
                                    <div className=" text-xl">{lang["BACKUP"]}</div>
                                </div>
                            </Button>
                            <Button
                                variant="text"
                                onClick={() => {
                                    // setshowLicenseModal(true);
                                }}
                            >
                                <div className="flex h-[150px] w-[300px] flex-col items-center justify-center rounded-md bg-zinc-300 p-1 font-semibold text-zinc-800">
                                    <img
                                        draggable={false}
                                        src={license}
                                        alt="database"
                                        className="h-10 w-10 rounded"
                                    />
                                    <div className=" text-xl">Software Solutions Private License</div>
                                    <div className=" text-md">Version: {sysFunc.appVersion}</div>
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {showBackupModal ? (
                <BackupModal modalShow={showBackupModal} setModalShow={setshowBackupModal} />
            ) : null}
            {showDataSelectionModel ? (
                <DataSelectionModel
                    modalShow={showDataSelectionModel}
                    setModalShow={setshowDataSelectionModel}
                />
            ) : null}
            {showLoginModel ? (
                <LoginModal
                    modalShow={showLoginModel}
                    setModalShow={setshowLoginModel}
                    login={login}
                    isError={isError}
                />
            ) : null}
        </>
    );

    function login(password) {
        const url = "admins/Login/";
        function handleLogin(res) {
            if (res.data.info === "failed") {
                setIsError(true);
            } else if (res.data.info === "success") {
                window.localStorage.setItem(
                    "admin",
                    JSON.stringify({
                        name: res.data.name,
                        dataname: res.data.dataname,
                        token: res.data.token,
                        rank: res.data.rank,
                        rank_name: res.data.rank_name,
                        pc_info: res.data.pc_info,
                    })
                );
                props.setUser({
                    name: res.data.name,
                    dataname: res.data.dataname,
                    token: res.data.token,
                    rank: res.data.rank,
                    rank_name: res.data.rank_name,
                    pc_info: res.data.pc_info,
                    settings: res.data.settings,
                    privileges: res.data.privilege,
                });
                window.localStorage.setItem("token", res.data.token);

                saveDefaultSettings(res.data.settings);
                savePrivilege(res.data.privilege);
                langHandler(res.data.settings?.system?.default?.language ?? 1);
                props.setisLogedIn(true);
            }
        }
        const data = { password: password, token: "" };
        alert.postdata(url, handleLogin, data, true);
    }
}
type THomeScreenProps = {
    setisLogedIn: React.Dispatch<React.SetStateAction<boolean>>;
    setUser: React.Dispatch<React.SetStateAction<TUser>>;
};
