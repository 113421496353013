import backup from "../../media/backup.png";
import React, { useState, useEffect } from "react";
import TextField from "../app2/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "../app2/Modal";
import Button from "../app2/Button";
import { useAlert } from "../../providers/AlertProvider";

export default function BackupModal(props) {
    const [folderPath1, setfolderPath1] = useState(window.localStorage.getItem("BUFolderPath1") ? window.localStorage.getItem("BUFolderPath1") : "");
    const [folderPath2, setfolderPath2] = useState(window.localStorage.getItem("BUFolderPath2") ? window.localStorage.getItem("BUFolderPath2") : "");
    const [folderPath3, setfolderPath3] = useState(window.localStorage.getItem("BUFolderPath3") ? window.localStorage.getItem("BUFolderPath3") : "");
    const [dataBases, setDataBases] = useState([]);

    const [selectedDB, setselectedDB] = useState(window.localStorage.getItem("dbname"));

    const [showSpinner, setshowSpinner] = useState(false);
    const alert = useAlert();

    useEffect(() => {
        fetchDatabases();
    }, []);
    return (
        <>
            <Modal
                show={props.modalShow}
                setshow={() => props.setModalShow(false)}
                title={
                    <div className="flex flex-row">
                        <img draggable={false} src={backup} alt="" className="mr-2 h-10 w-10 rounded" />
                        Backup Page
                    </div>
                }
                height="25rem"
                width="40rem"
            >
                <div className="flex flex-col gap-3">
                    <TextField
                        sx={{ width: 520 }}
                        size="small"
                        label="Path 1"
                        value={folderPath1}
                        id="path1"
                        onChange={(e) => {
                            setfolderPath1(e.target.value);
                        }}
                    />
                    <TextField
                        sx={{ width: 520 }}
                        size="small"
                        label="Path 2"
                        value={folderPath2}
                        id="path2"
                        onChange={(e) => {
                            setfolderPath2(e.target.value);
                        }}
                    />
                    <TextField
                        sx={{ width: 520 }}
                        size="small"
                        label="Path 3"
                        value={folderPath3}
                        id="path3"
                        onChange={(e) => {
                            setfolderPath3(e.target.value);
                        }}
                    />

                    <div>
                        <FormControl sx={{ width: 200 }} size="small">
                            <InputLabel id="demo-simple-select-label">Database</InputLabel>
                            <Select
                                value={selectedDB}
                                label="Database"
                                placeholder="Database"
                                id="Database"
                                name="db"
                                onChange={(e, f) => {
                                    setselectedDB(e.target.value);
                                }}
                            >
                                {dataBases.map((db) => {
                                    return (
                                        <MenuItem key={db} value={db}>
                                            {db}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="my-2 flex flex-row justify-center">
                    <Button
                        onClick={() => {
                            backUpData();
                            window.localStorage.setItem("BUFolderPath1", folderPath1);
                            window.localStorage.setItem("BUFolderPath2", folderPath2);
                            window.localStorage.setItem("BUFolderPath3", folderPath3);
                            setshowSpinner(true);
                        }}
                    >
                        BACKUP
                    </Button>
                </div>
                <div className=" flex flex-row justify-center">
                    {/* {showSpinner ? (
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    ) : null} */}
                </div>
            </Modal>
        </>
    );
    function fetchDatabases() {
        const url = "Get_Databases/";
        const cbnf = (res) => {
            setDataBases(res.data.data);
        };
        let token = "";
        try {
            token = JSON.parse(localStorage.getItem("serversData")).selectedServer.token;
        } catch (error) {
            console.log(error);
        }

        alert.postdata(url, cbnf, { prefix: "bo", sys_token: token }, false);
    }

    function backUpData() {
        const url = "backup_database/";
        const cbnf = (res) => {
            setshowSpinner(false);
        };

        let fdata = {
            path1: folderPath1,
            path2: folderPath2,
            path3: folderPath3,
            budbname: selectedDB,
        };
        alert.postdata(url, cbnf, fdata, true);
    }
}
