import { useEffect, useState } from "react";

type TImageViewProps = {
    apiRef?: React.MutableRefObject<TImageViewAPI>;
    defaultImage?: string;
    onClick?: (e) => void;
    style?: React.CSSProperties;
};
export type TImageViewAPI = {
    setimgeName: (src: string) => void;
};
export default function ImageView(props: TImageViewProps) {
    const [imgeName, setimgeName] = useState(props.defaultImage || "");
    if (props.apiRef) {
        props.apiRef.current = {
            setimgeName,
        };
    }
    useEffect(() => {
        setimgeName(props.defaultImage);
    }, [props.defaultImage]);
    const { host, port } = JSON.parse(window.localStorage.getItem("serversData"))["selectedServer"];
    const dataName = window.localStorage.getItem("dbname");
    if (imgeName === "") return null;
    return (
        <img
            className="w-full  rounded-lg object-cover"
            style={{
                cursor: props.onClick ? "pointer" : "default",
                ...props.style,
                objectFit: "contain",
            }}
            alt="image"
            src={
                (port === "443" ? "https" : "http") + `://${host}:${port}/get_img/${dataName}/${imgeName}`
            }
            onClick={props.onClick}
        />
    );
}
