// import Modal from "react-bootstrap/Modal";
import Modal from "../app2/Modal";
import Button from "../app2/Button";
import React from "react";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import NumPad from "../app2/NumPad";
import { FormHelperText } from "@mui/material";

export default function LoginModal(props) {
    const [showPassword, setShowPassword] = React.useState(false);
    const [password, setPassword] = React.useState("");
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <>
            <Modal show={props.modalShow} setshow={() => props.setModalShow(false)} title="Login" width="min(30rem,90vw)" height="min(40rem,90vh)">
                <div className="flex h-full w-full flex-col items-center gap-2 p-3">
                    <FormControl variant="standard" fullWidth>
                        <InputLabel htmlFor="pInput">Password</InputLabel>
                        <Input
                            error={props.isError}
                            autoFocus
                            id="pInput"
                            type={showPassword ? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            value={password}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    props.login(password);
                                }
                            }}
                        />
                        {props.isError ? <FormHelperText sx={{ color: "red" }}>Password Incorrect</FormHelperText> : null}
                    </FormControl>
                    <NumPad
                        callback={props.callback}
                        closeModal={props.setModalShow}
                        input={password}
                        setInput={setPassword}
                        elementIdToReFocus={"pInput"}
                        login={props.login}
                    />
                </div>
            </Modal>
        </>
    );
}
