import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

function LoadingSpinner({ show }) {
    if (show) {
        return (
            <div className="mx-2" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
                <CircularProgress size={"1rem"} />
            </div>
        );
    } else {
        return null;
    }
}

export default LoadingSpinner;
