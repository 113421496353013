import React, { createContext, ReactNode, useContext } from "react";
import { useAlert } from "./AlertProvider";
import { TTabsContainerApiRef } from "../components/App/NavTabs/TabsContainer";
import { apiParams } from "./typs";

// Define the types for your context
type TGoodsFunctions = {
    fetchData: (params: TFetchDataParamas) => void;
    updateItem: (params: TUpdateItemParams) => void;
    createItem: (params: TCreateItemParams) => void;
    openItemCard: (params: THandleOpenItemCard) => void;
    requestStatmentOfItem: (params: TRequestStatmentOfItem) => void;
    requestStockOverView: (params: TRequestStockOverView) => void;
    transferItemToOtherBarcode: (params: TTransferItemToOtherBarcode) => void;
    selectPacking: (params: TSelectPacking) => void;
    insertPacking: (params: TInsertPacking) => void;
    updatePacking: (params: TUpdatePacking) => void;
    deletePacking: (params: TDeletePacking) => void;
    getExtraInfo: (params: TGetExtraInfo) => void;
    deleteItem: (params: TDeleteItem) => void;
    updateItemCode: (params: TUpdateItemParams) => void;
    multiUpdateItem2: (params: { items; field; value; onSuccess; onError? }) => void;
};

// Create a context with default values
const GoodsFunctionsContext = createContext<TGoodsFunctions | undefined>(undefined);

// Custom hook to use the context
export const useGoodsFunctions = () => {
    const context = useContext(GoodsFunctionsContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};

interface GoodsFuncProviderProps {
    children: ReactNode;
    tabContainerApiRef: React.MutableRefObject<TTabsContainerApiRef>;
}

export const GoodsFuncProvider: React.FC<GoodsFuncProviderProps> = ({
    children,
    tabContainerApiRef,
}) => {
    const alert = useAlert();
    const value: TGoodsFunctions = {
        updateItemCode: ({ id, field, value, onSuccess = () => {}, onError = () => {} }) => {
            const url = "goods/edit_itemcode/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            const data = {
                id: id,
                field: field,
                value: value,
            };
            alert.postdata(url, cbnf, data, false);
        },
        fetchData: ({
            filters = {},
            limit = 50000,
            showOtherBarcode = true,
            onSuccess = (res) => {},
            onError = (res) => {},
        }) => {
            const url = "goods/get_items/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, { filters: filters, limit: limit, showOtherBarcode }, false);
        },

        updateItem: (params: TUpdateItemParams) => {
            const url = "goods/edit_item/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    params.onSuccess(res);
                } else {
                    params?.onError && params?.onError(res);
                }
            };
            const data = {
                id: params.id,
                field: params.field,
                value: params.value,
            };
            alert.postdata(url, cbnf, data, false);
        },
        createItem: (params: TCreateItemParams) => {
            const url = "goods/create_new_item/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    params.onSuccess(res);
                } else {
                    params?.onError && params?.onError(res);
                }
            };
            const data = {
                data: params.data,
            };

            alert.postdata(url, cbnf, data, true);
        },

        openItemCard: (params: THandleOpenItemCard) => {
            localStorage.setItem("tempTabData", JSON.stringify(params.data));
            const cardTab = tabContainerApiRef.current.tabOptions.find((el) => el.value === "itemCard");
            tabContainerApiRef.current.addTab(cardTab);
        },
        requestStatmentOfItem: (params: TRequestStatmentOfItem) => {
            const url = "goods/statement_of_item/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    params.onSuccess(res);
                } else {
                    params?.onError && params?.onError(res);
                }
            };
            const data = {
                itemcode: params.itemcode,
                startDate: params.startDate,
                endDate: params.endDate,
                branchs: params.branchs,
                jobs: params.jobs,
                smans: params.smans,
                calculationType: params.calculationType,
                selectedGroup: params.selectedGroup,
            };

            alert.postdata(url, cbnf, data, false);
        },
        requestStockOverView: (params: TRequestStockOverView) => {
            const url = "goods/stock_overview/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    params.onSuccess(res);
                } else {
                    params?.onError && params?.onError(res);
                }
            };
            const data = {
                filters: params.filters,
                currency: params.currency,
                startdate: params.startdate,
                enddate: params.enddate,
                by: params.by,
                limit: params.limit,
                orderby: params.orderby,
                ordertype: params.ordertype,
                groupby: params.groupby,
                itemcodeFilter: params.itemcodeFilter,
            };

            alert.postdata(url, cbnf, data, false);
        },
        transferItemToOtherBarcode: (params: TTransferItemToOtherBarcode) => {
            const url = "goods/transfer_to_otherbarcode/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    params.onSuccess(res);
                } else {
                    params?.onError && params?.onError(res);
                }
            };
            const data = {
                itemcode: params.maincode,
                maincode: params.maincode,
            };

            alert.postdata(url, cbnf, data, false);
        },
        selectPacking: ({ data, onSuccess = () => {}, onError = () => {} }) => {
            const url = "goods/get_packing/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        insertPacking: ({ data, onSuccess = () => {}, onError = () => {} }) => {
            const url = "goods/insert_packing/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        updatePacking: ({ data, onSuccess = () => {}, onError = () => {} }) => {
            const url = "goods/update_packing/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        deletePacking: ({ data, onSuccess = () => {}, onError = () => {} }) => {
            const url = "goods/delete_packing/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        getExtraInfo: ({ data, onSuccess = () => {}, onError = () => {} }) => {
            const url = "goods/get_extra_info/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, false);
        },
        deleteItem: ({ data, onSuccess = () => {}, onError = () => {} }) => {
            const url = "goods/delete_item/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            alert.postdata(url, cbnf, data, true);
        },
        multiUpdateItem2: ({ items, field, value, onSuccess = () => {}, onError = () => {} }) => {
            const url = "goods/multi_edit_items2/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };
            const data = {
                items: items,
                field: field,
                value: value,
            };
            alert.postdata(url, cbnf, data, false);
        },
    };

    return <GoodsFunctionsContext.Provider value={value}>{children}</GoodsFunctionsContext.Provider>;
};

export type TDeleteItem = apiParams<{
    itemcode: string;
}>;
export type TSelectPacking = apiParams<{
    itemcode: string;
}>;
export type TGetExtraInfo = apiParams<{
    itemcode;
    branch;
}>;
export type TInsertPacking = apiParams<{
    itemcode;
    packing_id;
    qty;
}>;
export type TUpdatePacking = apiParams<{
    id;
    field;
    itemcode;
    value;
}>;
export type TDeletePacking = apiParams<{
    id;
    itemcode;
}>;

export type TFetchDataParamas = {
    filters?: any;
    limit?: number;
    showOtherBarcode?;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};

export type TUpdateItemParams = {
    id?: any;
    field?: any;
    value?: any;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};

export type TCreateItemParams = {
    data?: any;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TRequestStatmentOfItem = {
    itemcode?: any;
    startDate?: any;
    endDate?: any;
    branchs?: any;
    jobs?: any;
    smans?: any;
    calculationType?: any;
    selectedGroup: any;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type THandleOpenItemCard = {
    data;
};
export type TRequestStockOverView = {
    filters?;
    currency?;
    startdate?;
    enddate?;
    by?;
    limit?;
    orderby?;
    ordertype?;
    groupby?;
    itemcodeFilter;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};

export type TTransferItemToOtherBarcode = {
    itemcode?: any;
    maincode?: any;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
