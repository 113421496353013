import React, { Component } from "react";

class ErrorHandler extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorMessage: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service here
        console.error(error, errorInfo);
        this.setState({ errorMessage: error.toString() });
    }

    render() {
        if (this.state.hasError) {
            // You can return your custom error page component here
            return (
                <div className="flex h-[100dvh] flex-col items-center gap-[5rem] bg-zinc-800 p-4 text-xl font-semibold  !text-[#c7c7c7]">
                    <div>An Unexpected Error Happend!!</div>
                    <button
                        className="rounded border-2 p-2 "
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        Reload
                    </button>
                    <div>If This Error Persistes Please Contact Support</div>
                    <p className=" text-red-400">{this.state.errorMessage}</p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorHandler;
