import React, { createContext, ReactNode, useContext } from "react";
import { useAlert } from "./AlertProvider";
import { apiParams } from "./typs";

// Define the types for your context
type TCRMFunctions = {
    getSubscriptions: (params: TGetSubscriptionsParamas) => void;
    insertSubscription: (params: TInsertSubscriptionParamas) => void;
    deleteSubscription: (params: TDeleteSubscriptionParamas) => void;
    postSubscriptions: (params: TPostSubscriptionsParamas) => void;
    editSubscription: (params: TEditSubscriptionParamas) => void;
};

// Create a context with default values
const CRMFunctionsContext = createContext<TCRMFunctions | undefined>(undefined);

// Custom hook to use the context
export const useCRM = () => {
    const context = useContext(CRMFunctionsContext);
    if (context === undefined) {
        throw new Error("useCRM must be used within a CRMProvider");
    }
    return context;
};

interface CRMFuncProviderProps {
    children: ReactNode;
}

export const CRMFuncProvider: React.FC<CRMFuncProviderProps> = ({ children }) => {
    const alert = useAlert();
    const value: TCRMFunctions = {
        getSubscriptions: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "crm/get_subscriptions/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf,  data , false);
        },
        insertSubscription: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "crm/insert_subscription/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        deleteSubscription: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "crm/delete_subscription/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        postSubscriptions: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "crm/post_subscriptions/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, true);
        },
        editSubscription: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "crm/edit_subscriptions/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, true);
        },
    };

    return <CRMFunctionsContext.Provider value={value}>{children}</CRMFunctionsContext.Provider>;
};

export type TGetSubscriptionsParamas = apiParams<{
    group_by_id?: boolean;
}>;
export type TInsertSubscriptionParamas = apiParams<{
    account_id;
    sub_id;
    price;
    start_date;
    end_date;
}>;
export type TDeleteSubscriptionParamas = apiParams<{
    subscription_id;
    post_id;
}>;
export type TEditSubscriptionParamas = apiParams<{
    subscription_id;
    is_posted;
    field;
    value;
}>;
export type TPostSubscriptionsParamas = apiParams<{
    filter?: string;
}>;
