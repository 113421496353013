import React, { createContext, ReactNode, useContext } from "react";
import { useAlert } from "./AlertProvider";
import { TTabsContainerApiRef } from "../components/App/NavTabs/TabsContainer";
import { TSelectedTab } from "../components/App/AppContainer";
import { TDataGridModalAPI } from "../components/app2/DataGridModal2";
import packageJson from "../../package.json";

// Define the types for your context
type TSystemFunctions = {
    tabContainerApiRef?: React.MutableRefObject<TTabsContainerApiRef>;
    addTabById: (id: { value: TSelectedTab["value"]; category: TSelectedTab["category"] }) => void;
    dataGridModalApiRef: React.MutableRefObject<TDataGridModalAPI>;
    appVersion:  string;
};

// Create a context with default values
const SystemFunctionsContext = createContext<TSystemFunctions | undefined>(undefined);

// Custom hook to use the context
export const useSystemFunctions = () => {
    const context = useContext(SystemFunctionsContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};

interface SystemFuncProviderProps {
    children: ReactNode;
    tabContainerApiRef: React.MutableRefObject<TTabsContainerApiRef>;
    dataGridModalApiRef: React.MutableRefObject<TDataGridModalAPI>;
}

export const SystemFuncProvider: React.FC<SystemFuncProviderProps> = ({ children, tabContainerApiRef, dataGridModalApiRef }) => {
    const alert = useAlert();

    const value: TSystemFunctions = {
        tabContainerApiRef,
        addTabById: (id) => {
            const ntab = tabContainerApiRef.current.tabOptions.filter((el) => {
                return el.value === id.value && el.category === id.category;
            });
            if (ntab.length !== 1) {
                alert.showAlert("unauthurized", "Tab Could Not Be Opend ");
                return;
            }
            tabContainerApiRef.current.addTab(ntab[0]);
        },
        dataGridModalApiRef,
        appVersion: packageJson.version
    };

    return <SystemFunctionsContext.Provider value={value}>{children}</SystemFunctionsContext.Provider>;
};

export type TFetchDataParamas = {
    filters?: any;
    limit?: number;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
