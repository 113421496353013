import React, { createContext, ReactNode, useContext, useState, useRef, useEffect, CSSProperties } from "react";

// Define the types for your context

// Create a context with default values
const ContextMenuApiContext = createContext<React.MutableRefObject<TContextMenuApi> | undefined>(undefined);

// Custom hook to use the context
export const useContextMenuApi = () => {
    const context = useContext(ContextMenuApiContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a MenuProvider");
    }
    return context;
};

interface ContextMenuApiProviderProps {
    children: ReactNode;
}

export const ContextMenuApiProvider: React.FC<ContextMenuApiProviderProps> = ({ children }) => {
    const menuApi = useRef<TMenuApi>();
    const contextMenuApi = useRef<TContextMenuApi>({
        menuApi: menuApi,
    });
    return (
        <ContextMenuApiContext.Provider value={contextMenuApi}>
            <ContextMenu menuApi={menuApi} />
            {children}
        </ContextMenuApiContext.Provider>
    );
};

const ContextMenu = ({ menuApi }: TContextMenuProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [menuOptions, setMenuOptions] = useState<TContextMenuOption[]>([]);

    const menuRef = useRef();

    menuApi.current = {
        handleShowMenu: (options, event) => {
            event.preventDefault();
            setMenuOptions(options);
            setPosition({ x: event.pageX, y: event.pageY });
            setIsVisible(true);
        },
    };

    useEffect(() => {
        if (!menuRef.current) return;

        const { innerWidth, innerHeight } = window;
        const { offsetWidth, offsetHeight } = menuRef.current;
        const { x, y } = position;

        const newX = x + offsetWidth > innerWidth ? x - offsetWidth : x;
        const newY = y + offsetHeight > innerHeight ? y - offsetHeight : y;

        setPosition({ x: newX, y: newY });
    }, [position.x, position.y]);

    const style: CSSProperties = {
        position: "fixed",
        top: `${position.y}px`,
        left: `${position.x}px`,
    };

    return (
        <div
            className="absolute z-[1000] block h-[100vh] w-[100vw]"
            style={{ backgroundColor: "#00000042", display: isVisible ? "block" : "none", zIndex: 1000000 }}
            onClick={() => setIsVisible(!isVisible)}
        >
            <div ref={menuRef} style={style} className="flex min-w-[7rem] flex-col rounded  border-2 border-pri bg-sys-sec p-1">
                {menuOptions.map((option, index) => (
                    <div key={index} onClick={option.onClick} className=" cursor-pointer  rounded-lg bg-sys-sec px-2 py-1 text-pri hover:bg-hover">
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    );
};
type TContextMenuApi = {
    menuApi: React.MutableRefObject<TMenuApi>;
};
type TMenuApi = {
    handleShowMenu: (options: TContextMenuOption[], event: { pageX; pageY; preventDefault: () => void }) => void;
};
type TContextMenuProps = {
    menuApi: React.MutableRefObject<TMenuApi>;
};

type TContextMenuOption = {
    label: string;
    onClick: () => void;
};
