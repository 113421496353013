import { Modal as MuiModal, SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import XButton from "./XButton";
import React, { JSX, ReactNode } from "react";

/**
 * A modal component that displays content in a dialog window.
 * @param {Object} props - The props object containing the following properties:
 * @param {boolean} props.show - Whether the modal should be displayed or not.
 * @param {function} props.setshow - A function to set the value of `show`.
 * @param {string} [props.id=""] - The ID of the modal.
 * @param {boolean} [props.disableRestoreFocus=false] - Whether to disable restoring focus to the element that had focus prior to the modal opening.
 * @param {boolean} [props.disableEnforceFocus=true] - Whether to disable enforcing focus inside the modal.
 * @param {boolean} [props.disableEscapeKeyDown=true] - Whether to disable closing the modal when the Escape key is pressed.
 * @param {boolean} [props.disableAutoFocus=true] - Whether to disable automatically focusing on the first focusable element inside the modal.
 * @param {string} [props.width="75vw"] - The width of the modal.
 * @param {string} [props.height="70vh"] - The height of the modal.
 * @param {boolean} [props.defaultHeader=true] - Whether to use the default header or a custom header.
 * @param {JSX.Element} [props.CustomHeader=<div></div>] - The custom header to use if `defaultHeader` is `false`.
 * @param {string} [props.headerHeight="3rem"] - The height of the modal header.
 * @param {ReactNode} [props.title="No Title"] - The title of the modal.
 * @param {JSX.Element} props.children - The content to display inside the modal.
 * @param {function} [props.onTransitionEnter=() => {}] - A function to be called when the modal transition enters.
 * @param {string} [props.borderRadius="0.8rem"] - The border radius of the modal.
 * @returns {JSX.Element} - The modal component.
 */

export default function Modal({
    show,
    setshow,
    id = "",
    disableRestoreFocus = false,
    disableEnforceFocus = true,
    disableEscapeKeyDown = true,
    disableAutoFocus = true,
    width = "75vw",
    height = "70vh",
    defaultHeader = true,
    CustomHeader = <div></div>,
    headerHeight = "3rem",
    title = "No Title",
    children = null,
    onTransitionEnter = () => {},
    borderRadius = "0.8rem",
    showHeader = true,
    keepMounted = false,
    sx = {},
    modalSx = {},
    disableBackdropClick = false,
}: ModalProps) {
    let esc = 0;
    return (
        <MuiModal
            open={show}
            onClose={(e, r) => {
                if (r === "escapeKeyDown") {
                    if (esc === 0) {
                        esc = 1;
                        setTimeout(() => {
                            esc = 0;
                        }, 1000);
                    } else {
                        setshow(false);
                    }
                } else if (r === "backdropClick" && !disableBackdropClick) {
                    setshow(false);
                }
            }}
            id={id}
            disableRestoreFocus={disableRestoreFocus}
            disableEnforceFocus={disableEnforceFocus}
            disableAutoFocus={disableAutoFocus}
            onTransitionEnter={onTransitionEnter}
            keepMounted={keepMounted}
            sx={modalSx}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: `min(${width},95%)`,
                    height: height,
                    border: "2px solid ",
                    borderColor: "modal.borderColor",
                    boxShadow: 24,
                    borderRadius: borderRadius,
                    p: 0,
                    backgroundColor: "modal.primaryBG",
                    color: "text.secondary",
                    ...sx,
                }}
            >
                {showHeader && (
                    <Box
                        sx={{
                            height: headerHeight,
                            borderBottom: "2px solid ",
                            borderColor: "system.borderColor",
                        }}
                    >
                        {defaultHeader ? (
                            <div className="flex flex-row items-center justify-between  p-1 text-3xl font-semibold max-lg:text-base">
                                <div>{title}</div>
                                <div>
                                    <XButton onClickFunc={setshow} />
                                </div>
                            </div>
                        ) : (
                            CustomHeader
                        )}
                    </Box>
                )}
                <Box sx={{ height: `calc(${height} - ${headerHeight})` }}>{children}</Box>
            </Box>
        </MuiModal>
    );
}
export type ModalProps = {
    show: boolean;
    setshow: any;
    id?: string;
    disableRestoreFocus?: boolean;
    disableEnforceFocus?: boolean;
    disableEscapeKeyDown?: boolean;
    disableAutoFocus?: boolean;
    width?: string;

    height?: string;
    defaultHeader?: boolean;
    CustomHeader?: JSX.Element;
    headerHeight?: string;
    title?: string | ReactNode | Element | any;
    children: JSX.Element;
    onTransitionEnter?: any;
    borderRadius?: string;
    keepMounted?: boolean;
    showHeader?: boolean;
    sx?: SxProps<Theme>;
    modalSx?: SxProps<Theme>;
    disableBackdropClick?: boolean;
};
