import React, { useEffect, useRef } from "react";
// eslint-disable-next-line import/namespace
import TabsContainer, { TTabsContainerApiRef } from "./NavTabs/TabsContainer";
import Dashboard from "../../Pages/Others/Dashboard";
import DataGridModal2, { TDataGridModalAPI } from "../app2/DataGridModal2";
import { useAlert } from "../../providers/AlertProvider";
import { useUserFunctions } from "../../providers/UserFunctionsProvider";
import { useLanguage } from "../app2/LanguageContext";

export default function AppContainer(props: TAppContainerProps) {
    const alert = useAlert();
    const dataname = useUserFunctions().user.dataname;
    const Invoice2 = React.lazy(() => import("../../Pages/BackOffice/Invoice2"));
    const Goods = React.lazy(() => import("../../Pages/BackOffice/Goods"));
    const ItemCard = React.lazy(() => import("../../Pages/BackOffice/ItemCard"));
    const StockOverview = React.lazy(() => import("../../Pages/BackOffice/StockOverview"));
    const Accounts = React.lazy(() => import("../../Pages/BackOffice/Accounts"));
    const Vouchers = React.lazy(() => import("../../Pages/BackOffice/Vouchers"));
    const TrialBalance = React.lazy(() => import("../../Pages/BackOffice/TrialBalance"));
    const StatementOfAccount = React.lazy(() => import("../../Pages/BackOffice/StatementOfAccount"));
    // const Statistics = React.lazy(() => import("../../Pages/BackOffice/Statisctics"));
    const Employee = React.lazy(() => import("../../Pages/Attendance/Employees"));
    const Imports = React.lazy(() => import("../../Pages/Attendance/Imports"));
    const Exports = React.lazy(() => import("../../Pages/Attendance/Exports"));
    const GeneralSettings = React.lazy(() => import("../Settings/Modal/GeneralSettingsModal"));
    const ReportBuilder = React.lazy(() => import("../../Pages/ReportBuilder/ReportBuilder"));
    const Reports = React.lazy(() => import("../../Pages/Others/Reports"));
    const Subscriptions = React.lazy(() => import("../../Pages/CRM/Subscriptions"));
    const BranchTransfer = React.lazy(() => import("../../Pages/Others/BranchTransfer"));
    const { lang, langHandler } = useLanguage();
    
    const tabOptions: TTabOption[] = [
        { Element: Goods, title: lang["GOODS"], value: "goods", category: "Inventory" },
        { Element: ItemCard, title: lang["ITEM_CARD"], value: "itemCard", category: "Inventory" },
        { Element: Invoice2, title: lang["PURCHASE"], value: "purchase", category: "Inventory" },
        { Element: Invoice2, title: lang["SALES"], value: "sales", category: "Inventory" },
        { Element: Invoice2, title: lang["PROFORMA"], value: "proforma", category: "Inventory" },
        {
            Element: StockOverview,
            title: lang["STOCK_OVERVIEW"],
            value: "stockOverview",
            category: "Inventory",
        },
        { Element: Accounts, title: lang["ACCOUNTS"], value: "accounts", category: "Accounting" },
        {
            Element: StatementOfAccount,
            title: lang["STATEMENT_OF_ACC"],
            value: "statementOfAccount",
            category: "Accounting",
        },
        {
            Element: Vouchers,
            title: lang["JOURNAL_VOUCHER"],
            value: "journalVoucher",
            category: "Accounting",
        },
        {
            Element: Vouchers,
            title: lang["RECEIPT_VOUCHER"],
            value: "receiptVoucher",
            category: "Accounting",
            onClick: () => voucherHandler("CR"),
        },
        {
            Element: Vouchers,
            title: lang["PAYMENT_VOUCHER"],
            value: "paymentVoucher",
            category: "Accounting",
            onClick: () => voucherHandler("DB"),
        },
        {
            Element: TrialBalance,
            title: lang["TRIAL_BALANCE"],
            value: "trialBalance",
            category: "Accounting",
        },
        { Element: Employee, title: lang["EMPLOYEES"], value: "employees", category: "Payroll" },
        { Element: Imports, title: lang["ATTENDANCE"], value: "attendance", category: "Payroll" },
        { Element: Exports, title: lang["REPORTS"], value: "attReports", category: "Payroll" },
        {
            Element: GeneralSettings,
            title: lang["GENERAL_SETTINGS"],
            value: "generalSettings",
            category: "Others",
        },
        {
            Element: ReportBuilder,
            title: lang["REPORT_BUILDER"],
            value: "reportBuilder",
            category: "Others",
        },
        { Element: Reports, title: lang["REPORTS"], value: "reports", category: "Others" },
        { Element: Invoice2, title: "Branch Transfer", value: "branchTransfer", category: "Others" },
        {
            Element: () => <div>Coming Soon</div>,
            title: lang["COMING_SOON"],
            value: "comingSoon",
            category: "Production",
        },
        {
            Element: Subscriptions,
            title: lang["SUBSCRIPTIONS"],
            value: "subscriptions",
            category: "CRM",
        },
        // { Element: <></>, title: "Statistis", value: "statistis", category: "Others" },
        // { Element: <></>, title: "Reports", value: "reports", category: "Others" },
        // { Element: <></>, title: "Messages", value: "messages", category: "Others" },
        // { Element: <></>, title: "Notifications", value: "notifications", category: "Others" },
    ];
    const [openedTabs, setOpenedTabs] = React.useState<TSelectedTab[]>([]);
    const [selectedTab, setSelectedTab] = React.useState<TSelectedTab>({
        id: 1,
        title: "",
        value: "dashboard",
        category: "",
        Element: Dashboard,
        index: 0,
    });
    props.tabContainerApiRef.current = {
        tabOptions,
        selectedTab,
    };
    const user = useUserFunctions().user;
    React.useEffect(() => {
        const interval = setInterval(() => {
            backUpData();
        }, 60 * 60 * 1000);
        langHandler(user.settings?.system?.default?.language || 1);
        return () => clearInterval(interval);
    }, []);
    React.useEffect(() => {
        const changeTabHandler = (e: KeyboardEvent) => {
            if (e.ctrlKey && e.key === "Tab") {
                const index = openedTabs.findIndex(
                    (el) => el.value === selectedTab.value && el.category === selectedTab.category
                );
                const newIndex = index + 1 >= openedTabs.length ? 0 : index + 1;
                setSelectedTab(openedTabs[newIndex]);
            }
        };
        window.addEventListener("keydown", changeTabHandler);
        return () => window.removeEventListener("keydown", changeTabHandler);
    }, [selectedTab, openedTabs]);

    const [keyBinds, setKeyBinds] = React.useState<TKeyBind[]>([]);
    
    const handleKeyBinds = (event: KeyboardEvent, page, actionsMap: any[]) => {
        const b = keyBinds.filter((el) => el.page === page);
        b.forEach((el) => {
            if (
                el.key.toLowerCase() === event.key.toLowerCase() &&
                Boolean(el.is_ctrl) === event.ctrlKey &&
                Boolean(el.is_alt) === event.altKey
            ) {
                const r = actionsMap.filter((km) => km.key === el.action);
                if (r.length > 0) {
                    r[0]["action"]();
                    event.preventDefault();
                    event.stopPropagation();
                }
            }
        });
    };
    useEffect(() => {
        const url = "settings/get_keybindings/";
        const cbnf = (res) => {
            setKeyBinds(res.data.data);
        };

        alert.postdata(url, cbnf, {}, false);
    }, [openedTabs]);

    return (
        <div className={`h-full w-full `}>
            <TabsContainer
                tabOptions={tabOptions}
                openedTabs={openedTabs}
                setOpenedTabs={setOpenedTabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                tabContainerApiRef={props.tabContainerApiRef}
                keyBinds={keyBinds}
                setKeyBinds={setKeyBinds}
            />

            <React.Suspense fallback={<div>Loading...</div>}>
                {[
                    ...openedTabs,
                    { value: "dashboard", Element: Dashboard, id: 1, title: "DashBoard" },
                ].map((tab, idx) => {
                    return (
                        <div
                            key={tab.value + tab.id}
                            className={
                                tab.value + tab.id === selectedTab.value + selectedTab.id
                                    ? "block h-[calc(100%-3.5rem)] w-full"
                                    : "hidden "
                            }
                        >
                            <tab.Element
                                dataGridModalApiRef={props.dataGridModalApiRef}
                                title={tab.title}
                                tab={{ ...tab, index: idx }}
                                postdata={alert.postdata}
                                selectedTab={selectedTab}
                                keyBindHandler={handleKeyBinds}
                                keyBinds={keyBinds}
                            />
                        </div>
                    );
                })}
            </React.Suspense>

            <DataGridModal2 dataGridAPI={props.dataGridModalApiRef.current} />
        </div>
    );
    function voucherHandler(dbcr: "DB" | "CR") {
        window.localStorage.setItem("useTempAccounting", "true");
        window.localStorage.setItem("tempAccType", dbcr);
        window.localStorage.setItem("tempAccNum", "9999999");
    }
    function backUpData() {
        const url = "backup_database/";
        const cbnf = () => {
        };

        const fdata = {
            path1: window.localStorage.getItem("BUFolderPath1") ?? "",
            path2: window.localStorage.getItem("BUFolderPath2") ?? "",
            path3: window.localStorage.getItem("BUFolderPath3") ?? "",
            budbname: dataname,
        };
        alert.postdata(url, cbnf, fdata, true);
    }
}
type TAppContainerProps = {
    tabContainerApiRef: React.MutableRefObject<TTabsContainerApiRef>;
    dataGridModalApiRef: React.MutableRefObject<TDataGridModalAPI>;
};
export type TTabOption = {
    title: string;
    value: TTabValue;
    category: TTabCategory;
    Element?;
    onClick?;
};
export type TSelectedTab = {
    id: number;
    title?: string;
    value?: TTabValue;
    category?: TTabCategory;
    Element?;
    onTabClick?: () => void;
    index: number;
};

type TTabCategory = "Inventory" | "Accounting" | "Payroll" | "Others" | "" | "Production" | "CRM";

type TTabValue =
    | "dashboard"
    | "goods"
    | "itemCard"
    | "purchase"
    | "sales"
    | "proforma"
    | "stockOverview"
    | "accounts"
    | "statementOfAccount"
    | "journalVoucher"
    | "receiptVoucher"
    | "paymentVoucher"
    | "trialBalance"
    | "employees"
    | "attendance"
    | "attReports"
    | "generalSettings"
    | "statistis"
    | "reports"
    | "messages"
    | "comingSoon"
    | "reportBuilder"
    | "subscriptions"
    | "branchTransfer"
    | "notifications";

export type TKeyBind = {
    page: string;
    key: string;
    is_ctrl: number;
    is_alt: number;
    action: string;
};
