export function focusTo(id) {
    document.getElementById(id).focus();
}
export function blurDoc() {
    document.activeElement.blur();
}
export function getStorage(key) {
    return window.localStorage.getItem(key) === undefined ? "Error" : window.localStorage.getItem(key);
}
export function getJsonStorage(key) {
    return JSON.parse(window.localStorage.getItem(key));
}
export function saveDefaultSettings(settings) {
    window.localStorage.setItem("settings", JSON.stringify(settings));
}
export function savePrivilege(priv) {
    window.localStorage.setItem("privilege", JSON.stringify(priv));
}
