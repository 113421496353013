import ximg from "../../media/XButton2.png";
import React from "react";

export default function XButton(props) {
    return (
        <div
            className="m-1 h-[2rem] w-[2rem] cursor-pointer"
            onClick={() => {
                props.onClickFunc();
            }}
            id="ModalCloseButton"
        >
            <img draggable={false} src={ximg} alt="Exit" className="flex h-[2rem] w-[2rem]  " />
        </div>
    );
}
