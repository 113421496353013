import Modal from "./Modal";
import TextField from "./TextField";
import Button from "./Button";
import React, { useEffect, useState } from "react";
export default function ServerSelectionModal(props: TServerSelectionModalProps) {
    const [serversList, setserversList] = useState<TServerInfo[]>([]);
    const [selectedServer, setselectedServer] = useState<TServerInfo>();
    const [newServerInfo, setnewServerInfo] = useState<TServerInfo>({
        host: "127.0.0.1",
        port: 8000,
        token: "",
    });
    const [selectedServerInfo, setselectedServerInfo] = useState<TServerInfo>({
        host: "",
        port: 0,
        token: "",
    });

    useEffect(() => {
        const serversData = localStorage.getItem("serversData");
        if (serversData) {
            setserversList(JSON.parse(serversData)["serversList"]);
            setselectedServer(JSON.parse(serversData)["selectedServer"]);
        }
    }, []);

    const handleNewServerInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setnewServerInfo({ ...newServerInfo, [e.target.name]: e.target.value });
    };

    const handleAddServer = () => {
        let valid = true;
        serversList.forEach((server) => {
            if (server.host === newServerInfo.host) {
                valid = false;
                alert(`Server with Host ${server.host} already exists`);
            }
        });
        if (!valid) return;
        localStorage.setItem(
            "serversData",
            JSON.stringify({
                serversList: [...serversList, newServerInfo],
                selectedServer: newServerInfo,
            })
        );
        setserversList([...serversList, newServerInfo]);
        setnewServerInfo({ host: "", port: 0, token: "" });
    };

    return (
        <Modal title="Servers Config" show={props.show} setshow={props.setshow}>
            <div className="flex h-full w-full flex-row items-center justify-center">
                <div className="flex h-[95%] w-[50%] flex-col items-center justify-center gap-3 border-r-[1px]">
                    <div className=" text-xl font-semibold">Add Server</div>
                    <TextField
                        name="host"
                        label="host"
                        value={newServerInfo.host}
                        onChange={handleNewServerInfoChange}
                    />
                    <TextField
                        name="port"
                        label="port"
                        value={newServerInfo.port}
                        onChange={handleNewServerInfoChange}
                    />
                    <TextField
                        name="token"
                        label="token"
                        value={newServerInfo.token}
                        onChange={handleNewServerInfoChange}
                    />
                    <Button onClick={handleAddServer}>Add</Button>
                </div>
                <div className="flex h-[95%] w-[50%] flex-col items-center gap-3 ">
                    <div className=" text-xl font-semibold">Servers List</div>
                    <div className=" flex h-[30%] w-[80%] flex-col items-center justify-start gap-3 rounded border-2 p-2">
                        {serversList.map((server) => {
                            return (
                                <div
                                    className="flex w-full flex-row justify-between overflow-y-auto rounded border-2 p-2 shadow-lg"
                                    key={server.host}
                                >
                                    <div
                                        className={` ${
                                            server.host === selectedServer.host
                                                ? "text-blue-500"
                                                : ""
                                        }`}
                                    >
                                        {server.host} : {server.port}
                                    </div>
                                    <div className="flex flex-row gap-4">
                                        <div
                                            className=" cursor-pointer text-green-500"
                                            onClick={() => {
                                                setselectedServer(server);
                                                localStorage.setItem(
                                                    "serversData",
                                                    JSON.stringify({
                                                        serversList: serversList,
                                                        selectedServer: server,
                                                    })
                                                );
                                            }}
                                        >
                                            Select
                                        </div>
                                        <div
                                            className=" cursor-pointer text-red-500"
                                            onClick={() => {
                                                const newServersList = serversList.filter(
                                                    (s) => s.host !== server.host
                                                );
                                                setserversList(newServersList);
                                                localStorage.setItem(
                                                    "serversData",
                                                    JSON.stringify({
                                                        serversList: newServersList,
                                                        selectedServer: selectedServer,
                                                    })
                                                );
                                            }}
                                        >
                                            Delete
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

type TServerSelectionModalProps = {
    show: boolean;
    setshow: (show: boolean) => void;
};

export type TServerDataLocalStorage = {
    serversList: TServerInfo[];
    selectedServer: TServerInfo;
};

type TServerInfo = {
    host: string;
    port: number;
    token: string;
};
