import Box from "@mui/material/Box";
import DataTable from "./DataTable";
import React, { useState, useEffect, useRef } from "react";
import Modal from "./Modal";
import { asyncPostData } from "../../lib/Axios";
import { GridColDef, GridColType, useGridApiRef } from "@mui/x-data-grid";
import Button from "./Button";
import ImageView, { TImageViewAPI } from "./ImageView";
import { TUser, useUserFunctions } from "../../providers/UserFunctionsProvider";
type TDataGridModalProps = {
    dataGridAPI: TDataGridModalAPI;
};

export type TDataGridModalAPI = {
    show?: (type: "account" | "item", searchPhrase: string) => void;
    onEnterPress?: (id: string, row?: object) => void;
    onExitPress?: () => void;
    createItemFunction?: () => void;
};

export default function DataGridModal2(props: TDataGridModalProps) {
    const settings = useUserFunctions().user.settings;
    const [noFdata, setdata] = useState([]);
    const [cols, setcols] = useState([
        {
            field: "",
            headerName: "",
            width: 200,
            editable: false,
        },
    ]);
    const [isLoading, setisLoading] = useState(true);

    const dataTableRef = useRef();

    const [show, setShow] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState("");
    const [title, setTitle] = useState("");
    const [isItem, setIsItem] = useState(false);

    const extraItemInfoRef = useRef<HTMLDivElement>(null);
    const initialPosition = useRef({
        x: 0,
        y: 0,
    });

    const item = {
        query: (phrase) => {
            phrase = phrase.replace(/ /g, "%");
            const dbname = localStorage.getItem("dbname");
            return `itemcode ,originalnumber ,itemname1 ,itemname2 ,
            sale1 ,sale2 ,sale3 ,sale4 ,
            last_cost ,av_cost,all_qty ,active ,img1 ,g.description ,date_created ,last_modified ,
            gca.name as "category_name",
            gco.name as "color_name",
            gbr.name as "brand_name",
            gge.name as "group_name",
            gse.name as "set_name"
             FROM ${dbname}.goods g
            left join ${dbname}.goods_colors gco on g.color_id = gco.id
            left join ${dbname}.goods_categories gca on g.category_id = gca.id
            left join ${dbname}.goods_sets gse on g.set_id = gse.id
            left join ${dbname}.goods_brands gbr on g.brand_id = gbr.id
            left join ${dbname}.goods_groups gge on g.group_id = gge.id
            where 
            (itemcode LIKE '%${phrase}%' 
            OR originalnumber LIKE '%${phrase}%' 
            OR itemname1 LIKE '%${phrase}%' 
            OR itemname2 LIKE '%${phrase}%'
            OR CONCAT(g.itemname1, ' ', IFNULL(g.itemname2, ''), ' ', IFNULL(gco.name, ''), ' ', IFNULL(gca.name, ''), ' ', IFNULL(gse.name, ''), ' ', IFNULL(g.description, '')) LIKE '%${phrase}%')
            `;
        },
        title: "Similar Item Code Info",
    };
    const account = {
        query: (phrase) => {
            return `
            id as 'Acc Id',name1 as "Name 1",name2 as "Name 2",tax_number as "Tax Number",\`cur\` as "Currency",category as "Category" ,phone1 as "Phone 1",phone2 as "Phone 2"
            FROM ${localStorage.getItem("dbname")}.accounts 
            where ((\`id\` like '${phrase}%' ) or tax_number like '%${phrase}%' or (name1 like '%${phrase}' or name1 like '${phrase}%' or name1 like '%${phrase}%') or (name2 like '%${phrase}' or name2 like '${phrase}%' or name2 like '%${phrase}%') or (cur like '%${phrase}' or cur like '${phrase}%' or cur like '%${phrase}%') or (phone1 like '%${phrase}' or phone1 like '${phrase}%' or phone1 like '%${phrase}%') or (phone2 like '%${phrase}' or phone2 like '${phrase}%' or phone2 like '%${phrase}%') ) and active = 'Y'`;
        },
        title: "Similar Account Info",
    };

    props.dataGridAPI.show = showModal;

    const dataTableAPIRef = useGridApiRef();
    const defFilter = window.localStorage.getItem("dataGridModalFilters");
    const [itemsfilters, setitemsFilters] = useState(
        defFilter
            ? JSON.parse(defFilter)
            : { onlyActive: true, showImage: false, availableInStock: false }
    );
    const data = noFdata.filter((e) => {
        let isShown = true;
        if (isItem) {
            if (itemsfilters.onlyActive) {
                isShown = Boolean(e.active);
            }
            if (itemsfilters.availableInStock) {
                isShown = e.all_qty > 0;
            }
        }
        return isShown;
    });

    useEffect(() => {
        data.length > 0 && dataTableAPIRef.current.setCellFocus(data[0][cols[0].field], cols[0].field);
    }, [data]);
    const createItem = () => {
        props.dataGridAPI.createItemFunction();
        setdata([]);
        setShow(false);
    };
    const imageViewApiRef = useRef<TImageViewAPI>(null);
    const extraItemDataApiRef = useRef(null);
    useEffect(() => {
        //add event listener for keydown
        const handleKeyDown = (e) => {
            if (e.ctrlKey && e.key === "q") {
                createItem();
            }
        };
        const handleFocusIn = (e: FocusEvent) => {
            const target = e.target as HTMLElement;
            if (target.id === "datagridcell") {
                if (isItem) {
                    const selectedItem = data.filter((e) => {
                        return e.itemcode === target.parentElement.firstChild.lastChild.textContent;
                    })[0];
                    extraItemDataApiRef?.current?.setData(selectedItem);
                    imageViewApiRef.current.setimgeName(selectedItem.img1);
                }
            }
        };
        if (show) {
            window.addEventListener("keydown", handleKeyDown);
            window.addEventListener("focusin", handleFocusIn);
        }

        //cleanup
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("focusin", handleFocusIn);
        };
    }, [show, data, isItem]);
    // console.log(props.dataGridAPI.createItemFunction)
    return (
        <Modal
            show={show}
            setshow={() => {
                props.dataGridAPI.onExitPress?.();
                setShow(false);
                setdata([]);
            }}
            onTransitionEnter={() => {}}
            id={"DataGridModal"}
            disableAutoFocus
            disableRestoreFocus={false}
            // title={`${title} \n for "${searchPhrase}"`}
            title={
                <div className="flex flex-row gap-4">
                    <div>{`${title} \n for "${searchPhrase}"`}</div>
                    {props.dataGridAPI.createItemFunction && (
                        <div>
                            <Button onClick={createItem}>Create Item (ctrl+Q)</Button>
                        </div>
                    )}
                </div>
            }
            width="90vw"
            height="90vh"
        >
            <div className=" flex h-full flex-col gap-2 p-2">
                {isItem ? <ItemsFilters setFilters={setitemsFilters} filters={itemsfilters} /> : null}
                <Box
                    sx={{
                        height: "95%",
                        width: "100%",
                        display: "flex",
                        gap: "10px",
                        justifyContent: "space-between",
                    }}
                >
                    <div className="w-[calc(100%-320px)]" id="ctrl">
                        <DataTable
                            rows={data}
                            gridRef={dataTableRef}
                            apiRef={dataTableAPIRef}
                            columns={isItem ? itemCols : cols}
                            columnHeaderHeight={45}
                            rowHeight={35}
                            slotProps={{
                                cell: { id: "datagridcell" },
                            }}
                            showColumnVerticalBorder
                            showCellVerticalBorder
                            hideFooter
                            getRowId={(e) => {
                                return e[cols[0].field];
                            }}
                            onCellDoubleClick={(e) => {
                                props.dataGridAPI.onEnterPress(e.row[cols[0].field], e.row);
                                setdata([]);
                                setShow(false);
                            }}
                            editMode="cell"
                            onCellKeyDown={(e, f) => {
                                if (f.key === "Enter") {
                                    props.dataGridAPI.onEnterPress(e.row[cols[0].field], e.row);
                                    setdata([]);
                                    setShow(false);
                                }
                            }}
                            columnBuffer={15}
                        />
                    </div>

                    {isItem && (
                        <>
                            <div
                                className=" cursor-e-resize resize-x rounded-xl border-2 border-gray-500 hover:border-blue-500"
                                // draggable
                                onDragStart={(e) => {
                                    initialPosition.current = {
                                        x: e.clientX,
                                        y: e.clientY,
                                    };
                                }}
                                onDrag={(e) => {
                                    console.log("dragged", e.clientX, initialPosition.current.x);
                                    const dif = initialPosition.current.x - e.clientX;
                                    const iheight = parseInt(extraItemInfoRef.current.style.width) + dif;
                                    const table = document.getElementById("ctrl");
                                    if (iheight < 320) {
                                        extraItemInfoRef.current.style.width = "320px";
                                        table.style.width = `calc(100% - ${iheight}px)`;
                                        return;
                                    }
                                    initialPosition.current = {
                                        x: e.clientX,
                                        y: e.clientY,
                                    };
                                    //img
                                    extraItemInfoRef.current.style.width = `${iheight}px`;
                                    //table
                                    table.style.width = `calc(100% - ${iheight}px)`;
                                }}
                                onDragEnd={(e) => {
                                    console.log("dragged", e.clientX, initialPosition.current.x);
                                    const dif = initialPosition.current.x - e.clientX;
                                    const iheight = parseInt(extraItemInfoRef.current.style.width) + dif;
                                    const table = document.getElementById("ctrl");
                                    if (iheight < 320) {
                                        extraItemInfoRef.current.style.width = "320px";
                                        table.style.width = `calc(100% - ${iheight}px)`;
                                        return;
                                    }
                                    //img
                                    extraItemInfoRef.current.style.width = `${iheight}px`;
                                    //table
                                    table.style.width = `calc(100% - ${iheight}px)`;
                                }}
                                draggable
                            ></div>
                            <div
                                className="flex  h-full flex-col justify-between gap-2 max-lg:hidden"
                                style={{ width: "320px" }}
                                ref={extraItemInfoRef}
                            >
                                <div className=" h-[40%] w-full overflow-y-auto">
                                    <ExtraItemData
                                        apiRef={extraItemDataApiRef}
                                        systemSettings={settings.system.default}
                                    />
                                </div>
                                <div className="relative flex h-fit w-full items-center justify-center overflow-y-auto">
                                    <ImageView apiRef={imageViewApiRef} />
                                </div>
                            </div>
                        </>
                    )}
                </Box>
            </div>
        </Modal>
    );
    function selectQuary(quary) {
        const url = "invoice/custom_select/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setcols((e) => {
                    const temp = [];
                    res.data.data.cols.forEach((element) => {
                        temp.push({
                            field: element,
                            headerName: element,
                            width: 200,
                            editable: false,
                        });
                    });
                    return temp;
                });
                setdata(res.data.data.data);
            }
        };
        const fdata = {
            quary: quary,
        };
        asyncPostData(url, cbnf, fdata, false);
    }
    function showModal(type, searchPhrase) {
        setTitle(type === "account" ? account.title : item.title);
        setSearchPhrase(searchPhrase);
        selectQuary(type === "account" ? account.query(searchPhrase) : item.query(searchPhrase));
        setShow(true);
        setIsItem(type === "item");
    }
}

const ItemsFilters = (props: { setFilters; filters }) => {
    return (
        <div className="flex  items-center justify-center gap-8">
            <div className="flex items-center gap-2">
                <input
                    type="checkbox"
                    checked={props.filters.onlyActive}
                    onChange={(e) => {
                        props.setFilters({ ...props.filters, onlyActive: e.target.checked });
                        window.localStorage.setItem(
                            "dataGridModalFilters",
                            JSON.stringify({
                                ...props.filters,
                                onlyActive: e.target.checked,
                            })
                        );
                    }}
                />
                <label>Active Only</label>
            </div>
            <div className="flex items-center gap-2">
                <input
                    type="checkbox"
                    checked={props.filters.availableInStock}
                    onChange={(e) => {
                        props.setFilters({ ...props.filters, availableInStock: e.target.checked });
                        window.localStorage.setItem(
                            "dataGridModalFilters",
                            JSON.stringify({
                                ...props.filters,
                                availableInStock: e.target.checked,
                            })
                        );
                    }}
                />
                <label>In Stock Only</label>
            </div>
        </div>
    );
};
const itemCols: GridColDef[] = [
    { field: "itemcode", headerName: "Code", width: 200 },
    { field: "itemname1", headerName: "Name 1", width: 300 },
    { field: "originalnumber", headerName: "Original Number", width: 150 },
    { field: "all_qty", headerName: "Q.I.S", width: 100, description: "Quantity in Stock" },
    { field: "category_name", headerName: "Category", width: 100 },
    { field: "set_name", headerName: "Set", width: 100 },
    { field: "color_name", headerName: "Color", width: 100 },
    { field: "brand_name", headerName: "Brand", width: 100 },
    { field: "group_name", headerName: "Group", width: 100 },
];

const ExtraItemData = (props: {
    apiRef: React.MutableRefObject<{ setData }>;
    systemSettings: TUser["settings"]["system"]["default"];
}) => {
    const [data, setData] = useState(null);
    props.apiRef.current = {
        setData,
    };
    return (
        <div className="grid h-full w-full grid-cols-4 ">
            {data ? (
                <>
                    <div className=" col-span-4 mb-4 text-center text-xl">Item's Information</div>
                    <div>Code :</div>
                    <div className=" col-span-3">{data["itemcode"]}</div>
                    <div>Name 1 :</div>
                    <div className=" col-span-3">{data["itemname1"]}</div>
                    <div>Name 2 :</div>
                    <div className=" col-span-3">{data["itemname2"]}</div>
                    <div>Sale 1 :</div>
                    <div className=" col-span-3">{data["sale1"]}</div>
                    <div>Sale 2 :</div>
                    <div className=" col-span-3">{data["sale2"]}</div>
                    <div>Sale 3 :</div>
                    <div className=" col-span-3">{data["sale3"]}</div>
                    <div>Sale 4 :</div>
                    <div className=" col-span-3">{data["sale4"]}</div>
                    {Boolean(props.systemSettings.show_av_cost_in_search) && (
                        <>
                            <div>AV Cost :</div>
                            <div className=" col-span-3">
                                {parseFloat(data["av_cost"])?.toLocaleString()}
                            </div>
                        </>
                    )}
                    {Boolean(props.systemSettings.show_last_cost_in_search) && (
                        <>
                            <div>LS Cost :</div>
                            <div className=" col-span-3">
                                {parseFloat(data["last_cost"])?.toLocaleString()}
                            </div>
                        </>
                    )}
                    <div>Desc. :</div>
                    <div className=" col-span-3">{data["description"]}</div>
                    <div>C.Date :</div>
                    <div className=" col-span-3">{data["date_created"]}</div>
                    <div>M.Date :</div>
                    <div className=" col-span-3">{data["last_modified"]}</div>
                </>
            ) : (
                "No Item Selected"
            )}
        </div>
    );
};
