export const useLocalStorage = (key: string) => {
    const setItem = (value: unknown) => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
            console.log(error);
        }
    };

    const getItem = (parse: boolean) => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? (parse ? JSON.parse(item) : item) : undefined;
        } catch (error) {
            console.log(error);
        }
    };

    const removeItem = () => {
        try {
            window.localStorage.removeItem(key);
        } catch (error) {
            console.log(error);
        }
    };

    return { setItem, getItem, removeItem };
};

export function getLocalStorage<T extends boolean>(key: string, parse: T): T extends true ? object : string | undefined {
    try {
        const value = window.localStorage.getItem(key);
        return value ? (parse ? JSON.parse(value) : String(value)) : undefined;
    } catch (error) {
        alert(error);
        return undefined;
    }
}
export function setLocalStorage(key: string, value: string): void {
    try {
        window.localStorage.setItem(key, value);
    } catch (error) {
        alert(error);
    }
}
