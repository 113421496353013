import React, { createContext, ReactNode, useContext } from "react";
import { useAlert } from "./AlertProvider";
import { TTabsContainerApiRef } from "../components/App/NavTabs/TabsContainer";
import { apiParams } from "./typs";

// Define the types for your context
type TVouchersFunctions = {
    startUp: (params: TFetchDataParamas) => void;
    getVchData: (params: TGetVchDataParamas) => void;
    createNewVoucher: (params: TCreateNewVoucherParamas) => void;
    confirmTrans: (params: TConfirmTransParamas) => void;
    getTempTrans: (params: TGetTempTransParamas) => void;
    editTrans: (params: TEditTransParamas) => void;
    addTrans: (params: TAddTransParamas) => void;
    removeVchTrans: (params: TRemoveVchTransParamas) => void;
    autoFillTrans: (params: TAutoFillTransParamas) => void;
    restTrans: (params: TRestTransParamas) => void;
    getReceiptTrans: (params: TGetReceiptTransParamas) => void;
    editMainAccount: (params: TEditMainAccountParamas) => void;
    deleteTrans: (params: TDeleteTransParamas) => void;
};

// Create a context with default values
const VouchersFunctionsContext = createContext<TVouchersFunctions | undefined>(undefined);

// Custom hook to use the context
export const useVouchersFunctions = () => {
    const context = useContext(VouchersFunctionsContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};

interface VouchersFuncProviderProps {
    children: ReactNode;
}

export const VouchersFuncProvider: React.FC<VouchersFuncProviderProps> = ({ children }) => {
    const alert = useAlert();
    const value: TVouchersFunctions = {
        startUp: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/page_startup/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, { data }, false);
        },
        getVchData: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/get_vch_data/";

            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        createNewVoucher: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/create_vch/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        confirmTrans: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/confirm_trans/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        getTempTrans: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/get_temp_trans/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        editTrans: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/edit_vch_trans/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        addTrans: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/add_trans/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        removeVchTrans: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/remove_vch_trans/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        autoFillTrans: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/auto_fill_trans/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        restTrans: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/reset_trans/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        getReceiptTrans: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/get_receipt_data/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
        editMainAccount: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/edit_main_account/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },

        deleteTrans: ({ data, onSuccess = (res) => {}, onError = (res) => {} }) => {
            const url = "vouchers/delete_transactions/";
            const cbnf = (res) => {
                if (res.data.info === "success") {
                    onSuccess(res);
                } else {
                    onError(res);
                }
            };

            alert.postdata(url, cbnf, data, false);
        },
    };

    return <VouchersFunctionsContext.Provider value={value}>{children}</VouchersFunctionsContext.Provider>;
};

export type TFetchDataParamas = {
    data?: any;
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TGetVchDataParamas = {
    data?: {
        type;
        num;
        condition;
        fetchTrans;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TCreateNewVoucherParamas = {
    data?: {
        type;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TConfirmTransParamas = {
    data?: {
        vch_id;
        rate;
        rate_cur;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TGetTempTransParamas = {
    data?: {
        vch_id;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TEditTransParamas = {
    data?: {
        vch_id;
        line;
        field;
        value;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TAddTransParamas = {
    data?: {
        vch_id;
        line;
        acc_id;
        dbcr;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TRemoveVchTransParamas = {
    data?: {
        vch_id;
        line;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TAutoFillTransParamas = {
    data?: {
        selectedRow;
        rate;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TRestTransParamas = {
    data?: {
        vch_id;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TGetReceiptTransParamas = {
    data?: {
        vch_id;
        ptype;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};
export type TEditMainAccountParamas = {
    data?: {
        vch_id;
        ptype;
        mainAcc;
    };
    onSuccess?: (res) => void;
    onError?: (res) => void;
};

type TDeleteTransParamas = apiParams<{
    vch_id;
}>;
