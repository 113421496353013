import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
export default function AppTheme({ theme = "dark", children }) {
    const darkTheme = createTheme({
        palette: {
            mode: theme,
            ...(theme === "light"
                ? {
                      text: {
                          primary: "#333333",
                          secondary: "#555555",
                      },
                      system: {
                          borderColor: "#666666",
                          primaryBG: "#ffffffe3",
                          secondaryBG: "#f5f5f5",
                      },
                      modal: {
                          primaryBG: "#e4e4e4",
                          secondaryBG: "#f5f5f5",
                          borderColor: "#cccccc",
                      },
                      tabs: {
                          primaryBG: "#f9f9f9",
                      },
                      tab: {
                          primaryBG: "#ffffff",
                          borderColor: "#cccccc",
                      },
                      dataTable: {
                          root: {
                              backgroundColor: "#ded8ca",
                              borderColor: "#000000",
                              color: "#000000",
                          },
                          header: {
                              color: "#0e0e0a",
                              backgroundColor: "#D0CDB7",
                          },
                      },
                      accordions: {
                          primaryBG: "#f0f0f0",
                      },
                      select: {
                          text: {
                              primary: "#111111",
                          },
                      },
                      button: {
                          color: {
                              primary: "#333333",
                          },
                          bgColor: {
                              blue: "#1976d2",
                              lightBlue: "#2196f3",
                              red: "#d32f2f",
                              lightred: "#f44336",
                              green: "#388e3c",
                              lightGreen: "#4caf50",
                              yellow: "#fbc02d",
                              lightYellow: "#ffeb3b",
                              orange: "#f57c00",
                              lightOrange: "#ff9800",
                              purple: "#7b1fa2",
                              lightPurple: "#9c27b0",
                              white: "#ffffff",
                              black: "#363636",
                          },
                      },
                  }
                : {
                      text: {
                          primary: "#d7d7d7",
                          secondary: "#cecece",
                      },
                      system: {
                          borderColor: "#919191",
                          primaryBG: "#171717",
                          secondaryBG: "#2b2b2b",
                      },
                      modal: {
                          primaryBG: "#171717",
                          secondaryBG: "#2b2b2b",
                          borderColor: "#6b6b6b",
                      },
                      tabs: {
                          primaryBG: "#0d0d0d",
                      },
                      tab: {
                          primaryBG: "#1b1b1b",
                          borderColor: "#6b6b6b",
                      },
                      accordions: {
                          primaryBG: "#2d2d2d",
                      },
                      input: {
                          text: {
                              primary: "#c6c6c6",
                              secondary: "#b9b9b9",
                              success: "#41c748",
                              error: "#ff2d2d",
                          },
                      },
                      button: {
                          color: {
                              primary: "#d5d5d5",
                          },
                          bgColor: {
                              blue: "#1976d2",
                              lightBlue: "#2196f3",
                              red: "#d32f2f",
                              lightred: "#f44336",
                              green: "#28ad2d",
                              lightGreen: "#4caf50",
                              yellow: "#fbc02d",
                              lightYellow: "#ffeb3b",
                              orange: "#f57c00",
                              lightOrange: "#ff9800",
                              purple: "#7b1fa2",
                              lightPurple: "#9c27b0",
                              white: "#ffffff",
                              black: "#bababa",
                          },
                      },
                  }),
        },
    });

    return <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>;
}
