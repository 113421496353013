// import Modal from "react-bootstrap/Modal";
import Modal from "../app2/Modal";
import React, { useEffect, useState } from "react";
import database from "../../media/database.png";
import Button from "../app2/Button";
import { Box, Input, Select } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ServerSelectionModal from "../app2/ServerSelectionModal";
import { useAlert } from "../../providers/AlertProvider";

export default function DataSeloectionModel(props) {
    const alert = useAlert();

    const [dataBases, setDataBases] = useState([]);
    const [randomKey, setrandomKey] = useState("");

    const [iKey, setiKey] = useState("");
    const [iDbname, setiDbname] = useState("");
    const [coa, setcoa] = useState("None");
    const [keyErrorMsg, setKeyErrorMsg] = useState("");

    const [sDatabase, setsDatabase] = useState(localStorage.getItem("dbname"));

    const [showServerSelectionModal, setshowServerSelectionModal] = useState(false);

    useEffect(() => {
        const serverData = localStorage.getItem("serversData");
        if (serverData === null) {
            setshowServerSelectionModal(true);
            localStorage.setItem("serversData", JSON.stringify({ serversList: [], selectedServer: null }));
        } else if (JSON.parse(serverData).selectedServer === null) {
            setshowServerSelectionModal(true);
        } else {
            fetchDatabases();
        }
    }, []);

    return (
        <>
            <Modal
                show={props.modalShow}
                setshow={props.setModalShow}
                title={
                    <div className="flex flex-row gap-2">
                        <img
                            draggable={false}
                            src={database}
                            alt=""
                            className="mr-2 h-10 w-10 rounded"
                            onClick={() => {
                                setshowServerSelectionModal(true);
                            }}
                        />
                        <div className=" max-lg:text-lg">Data Selection Page</div>
                    </div>
                }
            >
                <div className="flex h-full flex-col justify-between gap-3  p-3">
                    <div className="flex h-[50%] flex-row justify-between max-lg:hidden">
                        <div className="flex flex-col justify-around gap-3">
                            <div className="flex flex-row">
                                <div className=" mx-2 font-semibold">Selected Database: </div>
                                <div>{sDatabase}</div>
                            </div>
                        </div>
                        <div className="flex flex-col justify-end">
                            <div className=" flex flex-row justify-between text-right">
                                <div className=" text-red-600">{keyErrorMsg}</div>
                                <div>{randomKey}</div>
                            </div>
                            <Box className="flex flex-col rounded border-4" sx={{ borderColor: "system.borderColor" }}>
                                <Input
                                    placeholder="Key"
                                    type="password"
                                    value={iKey}
                                    onChange={(e) => {
                                        setiKey(e.target.value);
                                    }}
                                />
                                <Input
                                    placeholder="Name"
                                    value={iDbname}
                                    onChange={(e) => {
                                        setiDbname(e.target.value);
                                    }}
                                />
                                <FormControl sx={{ marginY: 1 }} size="small">
                                    <InputLabel sx={{ color: "#c7c7c7 !important" }} id="demo-simple-select-label">
                                        Chart Of Account
                                    </InputLabel>
                                    <Select
                                        value={coa}
                                        label="Chart Of Account"
                                        id="cof"
                                        name="Chart Of Account"
                                        onChange={(e) => {
                                            setcoa(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={"None"}>{"None"}</MenuItem>
                                        <MenuItem value={"lebanon_ar"}>{"Lebanon - AR"}</MenuItem>
                                        <MenuItem value={"lebanon_en"}>{"Lebanon - EN"}</MenuItem>
                                        <MenuItem value={"lebanon_fr"}>{"Lebanon - FR"}</MenuItem>
                                    </Select>
                                </FormControl>

                                <Button
                                    onClick={(e) => {
                                        createNewDatabase();
                                    }}
                                >
                                    Create New Database
                                </Button>
                            </Box>
                        </div>
                    </div>
                    <Box className=" h-[50%] max-lg:h-[100%] overflow-y-auto  rounded  p-4" sx={{ backgroundColor: "modal.secondaryBG" }}>
                        <div className="mx-auto flex flex-row flex-wrap">
                            {dataBases.map((db, idx) => {
                                return (
                                    <Button
                                        onClick={() => {
                                            localStorage.setItem("dbname", db);
                                            setsDatabase(db);
                                            window.location.reload();
                                        }}
                                        sx={{ margin: 2 }}
                                        key={idx}
                                        variant="outlined"
                                        size="large"
                                    >
                                        {db}
                                    </Button>
                                );
                            })}
                        </div>
                    </Box>
                </div>
            </Modal>
            {showServerSelectionModal && <ServerSelectionModal setshow={setshowServerSelectionModal} show={showServerSelectionModal} />}
        </>
    );

    function createNewDatabase() {
        let str = randomKey.toString();

        // remove the first character
        str = str.substring(1);

        // remove the last character
        str = str.substring(0, str.length - 1);

        if (str === iKey) {
            const url = "create_new_database/";
            const cbnf = (res) => {
                fetchDatabases();
            };

            alert.postdata(url, cbnf, { dbname: "bo_" + iDbname, chart: coa }, false);
        } else {
            setKeyErrorMsg("Invalid Key");
        }
    }

    function fetchDatabases() {
        const url = "Get_Databases/";
        const cbnf = (res) => {
            if (res.data.info === "success") {
                setDataBases(res.data.data);
                setrandomKey(res.data.rn);
            }
        };
        let token = "";
        try {
            token = JSON.parse(localStorage.getItem("serversData")).selectedServer.token;
        } catch (error) {
            console.log(error);
        }

        alert.postdata(url, cbnf, { prefix: "bo", sys_token: token }, false);
    }
    function connectToDatabase() {
        const url = "connect_to_database/";
        const cbnf = (res) => {};

        alert.postdata(url, cbnf, { prefix: "bo" }, false);
    }
}
