import React from "react";
import AppTheme from "./components/app2/AppTheme";
import { LanguageProvider } from "./components/app2/LanguageContext";
import AlertProvider, { AlertAPI } from "./providers/AlertProvider";
import HomeScreen from "./Pages/BackOffice/HomeScreen";
import Box from "@mui/material/Box";
import AppContainer from "./components/App/AppContainer";
import { TUser, UserFuncProvider } from "./providers/UserFunctionsProvider";
import { GoodsFuncProvider } from "./providers/GoodsFunctionsProvider";
import { InvoiceFuncProvider } from "./providers/InvoiceFunctionsProvider";
import { TTabsContainerApiRef } from "./components/App/NavTabs/TabsContainer";
import { SystemFuncProvider } from "./providers/SystemFunctionsProvider";
import { AccountsFuncProvider } from "./providers/AccountsFunctionsProvider";
import { VouchersFuncProvider } from "./providers/VouchersFunctionsProvider";
import { TDataGridModalAPI } from "./components/app2/DataGridModal2";
import { ContextMenuApiProvider } from "./providers/ContextMenuProvider";
import { CRMFuncProvider } from "./providers/CRMFunctionsProvider";

function App() {
    const [isLogedIn, setIsLogedIn] = React.useState<boolean>(false);
    const [alertAPI, setalertAPI] = React.useState<AlertAPI>({ showAlert: () => {}, hideAlert: () => {} });
    const [user, setUser] = React.useState<TUser>(null);
    const tabContainerApiRef = React.useRef<TTabsContainerApiRef>();
    const dataGridModalApiRef = React.useRef<TDataGridModalAPI>({});
    const theme = user?.settings?.system?.default?.use_dark_mode === 0 ? "light" : "dark";
    document.body.classList.add(theme);
    
    return (
        <>
            <AppTheme theme={theme}>
                <LanguageProvider>
                    <AlertProvider alertAPI={alertAPI} setalertAPI={setalertAPI}>
                        <div className={`flex h-[100vh] w-[100vw] items-start justify-start  `} style={{ backgroundColor: "black" }}>
                            <Box
                                className=" h-[100%] w-full overflow-y-hidden  "
                                sx={{ backgroundColor: "system.primaryBG", color: "text.secondary" }}
                            >
                                <ContextMenuApiProvider>
                                    <CRMFuncProvider>
                                        <VouchersFuncProvider>
                                            <AccountsFuncProvider tabContainerApiRef={tabContainerApiRef}>
                                                <GoodsFuncProvider tabContainerApiRef={tabContainerApiRef}>
                                                    <InvoiceFuncProvider tabContainerApiRef={tabContainerApiRef}>
                                                        <UserFuncProvider user={user} setUser={setUser}>
                                                            <SystemFuncProvider
                                                                tabContainerApiRef={tabContainerApiRef}
                                                                dataGridModalApiRef={dataGridModalApiRef}
                                                            >
                                                                {isLogedIn ? (
                                                                    <AppContainer
                                                                        tabContainerApiRef={tabContainerApiRef}
                                                                        dataGridModalApiRef={dataGridModalApiRef}
                                                                    />
                                                                ) : (
                                                                    <HomeScreen setisLogedIn={setIsLogedIn} setUser={setUser} />
                                                                )}
                                                            </SystemFuncProvider>
                                                        </UserFuncProvider>
                                                    </InvoiceFuncProvider>
                                                </GoodsFuncProvider>
                                            </AccountsFuncProvider>
                                        </VouchersFuncProvider>
                                    </CRMFuncProvider>
                                </ContextMenuApiProvider>
                            </Box>
                        </div>
                    </AlertProvider>
                </LanguageProvider>
            </AppTheme>
        </>
    );
}

export default App;
